/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import React from 'react';


import { ThemeProvider } from './src/contexts/GlobalContext';

export const wrapRootElement = ({ element }) => (
    <ThemeProvider>{element}</ThemeProvider>
);
