import React, { createContext, useState, useContext } from 'react';

// Create a context


const defaultState = {
    isBrowser:  typeof window !== "undefined",
    uid: null,
    currentTrainingDay: null,
    currentTrainingDayTitle: null,
    currentTrainingDayCategory: null,
    currentTrainingDayCategoryColor: null,
    currentTrainingDayDate: null,
    currentExercise: null,
    currentExerciseTitle: null,
    currentExercisePhoto: null,
    isLoggedIn: null,
    loader: false
}

const ThemeContext = React.createContext(defaultState)

// Initialize local storage record
const initLocalStorage = () => {
    if (typeof window !== "undefined" && !localStorage.getItem('apiStates')) {
        localStorage.setItem('apiStates', JSON.stringify(defaultState)); // Use defaultState here
    }
};

initLocalStorage();

export const getCachedValue = (key) => {
    if (typeof window !== "undefined") {
        const apiStates = JSON.parse(localStorage.getItem('apiStates'));
        return apiStates[key];
    }
    return null;
};

export const getStateValue = (contextValue, localStorageKey) => {
    if (contextValue !== null && contextValue !== undefined) {
        return contextValue;
    } else {
        return getCachedValue(localStorageKey);
    }
};

const setCachedValue = (key, value) => {
    if (typeof window !== "undefined") {
        const apiStates = JSON.parse(localStorage.getItem('apiStates'));
        apiStates[key] = value;
        localStorage.setItem('apiStates', JSON.stringify(apiStates));
    }
};


class ThemeProvider extends React.Component {
    state = {
        isBrowser: typeof window !== "undefined",
        uid: null,
        currentTrainingDay: null,
        currentTrainingDayTitle: null,
        currentTrainingDayCategory: null,
        currentTrainingDayCategoryColor: null,
        currentTrainingDayDate: null,
        currentExercise: null,
        currentExerciseTitle: null,
        currentExercisePhoto: null,
        isLoggedIn: null,
        loader: false
    }

    update_uid = (value) => {
        this.setState({ uid: value });
        setCachedValue('uid', value);
    }

    update_currentTrainingDay = (value) => {
        this.setState({ currentTrainingDay: value });
        setCachedValue('currentTrainingDay', value);
    }

    update_currentTrainingDayTitle = (value) => {
        this.setState({ currentTrainingDayTitle: value });
        setCachedValue('currentTrainingDayTitle', value);
    }

    update_currentTrainingDayCategory = (value) => {
        this.setState({ currentTrainingDayCategory : value });
        setCachedValue('currentTrainingDayCategory', value);
    }

    update_currentTrainingDayCategoryColor = (value) => {
        this.setState({ currentTrainingDayCategoryColor : value });
        setCachedValue('currentTrainingDayCategoryColor', value);
    }

    update_currentTrainingDayDate = (value) => {
        this.setState({ currentTrainingDayDate: value });
        setCachedValue('currentTrainingDayDate', value);
    }

    update_currentExercise = (value) => {
        this.setState({ currentExercise: value });
        setCachedValue('currentExercise', value);
    }

    update_currentExerciseTitle = (value) => {
        this.setState({ currentExerciseTitle: value });
        setCachedValue('currentExerciseTitle', value);
    }

    update_currentExercisePhoto = (value) => {
        this.setState({ currentExercisePhoto: value });
        setCachedValue('currentExercisePhoto', value);
    }

    update_isLoggedIn = (value) => {
        this.setState({ isLoggedIn: value });
        setCachedValue('isLoggedIn', value);
    }

    update_loader = (value) => {
        this.setState({ loader: value });
        setCachedValue('loader', value);
    }


    render() {
        const { children } = this.props
        const { isBrowser } = this.state
        const { uid } = this.state
        const { currentTrainingDay } = this.state
        const { currentTrainingDayTitle } = this.state
        const { currentTrainingDayCategory } = this.state
        const { currentTrainingDayCategoryColor } = this.state
        const { currentTrainingDayDate } = this.state
        const { currentExercise } = this.state
        const { currentExerciseTitle } = this.state
        const { currentExercisePhoto } = this.state
        const { isLoggedIn } = this.state
        const { loader } = this.state
        return (
            <ThemeContext.Provider
                value={{
                    isBrowser,
                    uid,
                    currentTrainingDay,
                    currentTrainingDayTitle,
                    currentTrainingDayCategory,
                    currentTrainingDayCategoryColor,
                    currentTrainingDayDate,
                    currentExercise,
                    currentExerciseTitle,
                    currentExercisePhoto,
                    isLoggedIn,
                    loader,

                    update_uid: this.update_uid,
                    update_currentTrainingDay: this.update_currentTrainingDay,
                    update_currentTrainingDayTitle: this.update_currentTrainingDayTitle,
                    update_currentTrainingDayCategory: this.update_currentTrainingDayCategory,
                    update_currentTrainingDayCategoryColor: this.update_currentTrainingDayCategoryColor,
                    update_currentTrainingDayDate: this.update_currentTrainingDayDate,
                    update_currentExercise: this.update_currentExercise,
                    update_currentExerciseTitle: this.update_currentExerciseTitle,
                    update_currentExercisePhoto: this.update_currentExercisePhoto,
                    update_isLoggedIn: this.update_isLoggedIn,
                    update_loader: this.update_loader
                }}
            >
                {children}
            </ThemeContext.Provider>
        )
    }
}

export default ThemeContext

export { ThemeProvider }
