exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-add-day-js": () => import("./../../../src/pages/add-day.js" /* webpackChunkName: "component---src-pages-add-day-js" */),
  "component---src-pages-add-exercise-js": () => import("./../../../src/pages/add-exercise.js" /* webpackChunkName: "component---src-pages-add-exercise-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-day-js": () => import("./../../../src/pages/day.js" /* webpackChunkName: "component---src-pages-day-js" */),
  "component---src-pages-exercise-js": () => import("./../../../src/pages/exercise.js" /* webpackChunkName: "component---src-pages-exercise-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manage-types-js": () => import("./../../../src/pages/manage-types.js" /* webpackChunkName: "component---src-pages-manage-types-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */)
}

